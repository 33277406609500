<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <h4>Students</h4>
        </div>
      </div>
      <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Student-Affairs-Students' }">Students</router-link>
          </li>
          <li class="breadcrumb-item active">View</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="list-view" class="tab-pane fade active show col-md-9">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">Student`s Profile</h4>
            <div class="btn-group" role="group">
              <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                Actions
              </button>
              <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-start" style="
                              position: absolute;
                              will-change: transform;
                              top: 0px;
                              right: 0px;
                              transform: translate3d(0px, 35px, 0px);
                            ">
                <a v-can="'lock_payment_profile_students'" class="dropdown-item" href="javascript:void()"
                  data-toggle="modal" data-target="#managePaymentProfile"
                  style="border-bottom: 1px solid #80808038">Payment`s Profile
                </a>
                <router-link v-if="student" v-can="'access_other_fees'" :to="{
                  name: 'Student-Affairs-Other-Fees-Student',
                  params: { student_id: student_id },
                }" class="dropdown-item">Manage Other Fees</router-link>

                <router-link v-if="student" v-can="'access_student_transactions'" :to="{
                  name: 'Student-Affairs-Manage-Student-Credit',
                  query: { student_id: student_id },
                }" class="dropdown-item">Manage Credit</router-link>

                <a v-can="'lock_payment_profile_students'" class="dropdown-item" href="javascript:void()"
                  @click.prevent="sendResultsReport()" style="border-bottom: 1px solid #80808038">Send Results
                </a>
                <a class="dropdown-item" href="javascript:void()" data-toggle="modal" data-target="#transferStdClass"
                  style="border-bottom: 1px solid #80808038">Class Transfer
                </a>
              </div>
            </div>
          </div>
          <div class="card-body" v-if="student">
            <!-- Student Information -->
            <div class="student-information">
              <div class="form-group row">
                <label class="col-md-12 col-form-label" style="color: #781b20"><i class="custom-left-border"></i> Student
                  Information</label>
              </div>
              <div class="custom-left-tall-border">
                <!-- Fullname [en]-->
                <div class="form-group row">
                  <label class="col-md-2 col-form-label">Code </label>
                  <label class="col-md-4 col-form-label" v-if="student.user">
                    {{ student.user.code }}
                  </label>

                  <label class="col-md-2 col-form-label">National ID/Passport
                  </label>
                  <label class="col-md-4 col-form-label">
                    {{ student.national_id }}
                    {{ student.passport }}
                  </label>
                </div>
                <!-- Fullname [Ar]-->
                <div class="form-group row">
                  <label class="col-md-2 col-form-label">Name [English] </label>
                  <label class="col-md-4" v-if="student.user">
                    {{ student.user.firstname }}
                    {{ student.user.middlename }}
                    {{ student.user.lastname }}
                  </label>
                  <label class="col-md-2 col-form-label">Name [Arabic] </label>
                  <label class="col-md-4">
                    {{ student.firstname_ar }}
                    {{ student.middlename_ar }}
                    {{ student.lastname_ar }}
                  </label>
                </div>
                <!-- Level and Class -->
                <div class="form-group row">
                  <label class="col-md-2 col-form-label">Level </label>
                  <label class="col-md-4" v-if="student.level">
                    {{ student.level.value }}
                  </label>
                  <label class="col-md-2 col-form-label">Class </label>
                  <label class="col-md-4" v-if="student.class">
                    {{ student.class.name }}
                  </label>
                </div>
                <!-- Email and Phone -->
                <div class="form-group row">
                  <label class="col-md-2 col-form-label">Email </label>
                  <label class="col-md-4" v-if="student.user">
                    {{ student.user.email }}
                  </label>
                  <label class="col-md-2 col-form-label">Phone </label>
                  <label class="col-md-4" v-if="student.user">
                    {{ student.user.phone }}
                  </label>
                </div>
                <!-- Country and City of the Birth -->
                <div class="form-group row">
                  <label class="col-md-2 col-form-label">Country of Birth
                  </label>
                  <label class="col-md-4" v-if="student.birth_country">
                    {{ student.birth_country.value }}
                  </label>
                  <label class="col-md-2 col-form-label">City of Birth </label>
                  <label class="col-md-4">
                    {{ student.birth_city_name }}
                  </label>
                </div>
                <!-- Age and Gender -->
                <div class="form-group row">
                  <label class="col-md-2 col-form-label">Age at First of Next October
                  </label>
                  <label class="col-md-4">
                    {{ student.age }}
                  </label>
                  <label class="col-md-2 col-form-label">Gender </label>
                  <div class="col-md-4">
                    {{ student.gender }}
                  </div>
                </div>
                <!-- Nationality and Religion-->
                <div class="form-group row">
                  <label class="col-md-2 col-form-label">Nationality </label>
                  <label class="col-md-4" v-if="student.nationality">
                    {{ student.nationality.value }}
                  </label>
                  <label class="col-md-2 col-form-label">Religion </label>
                  <label class="col-md-4">
                    {{ student.religion }}
                    {{ student.std_other_religion }}
                  </label>
                </div>

                <!-- Second Language and Transportation -->
                <div class="form-group row">
                  <label class="col-md-2 col-form-label">Second Language</label>
                  <label class="col-md-4" v-if="student.second_language">
                    {{ student.second_language.value }}
                  </label>
                  <label class="col-md-2 col-form-label">Transportation </label>
                  <label class="col-md-4">
                    {{ student.transportation_type }}
                  </label>
                </div>

                <!-- Previous School and Last Grade -->
                <div class="form-group row">
                  <label class="col-md-2 col-form-label">Previous School
                  </label>
                  <label class="col-md-4">
                    {{ student.previous_school }}
                  </label>
                  <label class="col-md-2 col-form-label">Last Grade </label>
                  <label class="col-md-4">
                    {{ student.last_grade }}
                  </label>
                </div>

                <!-- Number of Brothers and Number of Sisters -->
                <div class="form-group row">
                  <label class="col-md-2 col-form-label">Number of Brothers
                  </label>
                  <div class="col-md-4">
                    {{ student.number_of_brothers }}
                  </div>
                  <label class="col-md-2 col-form-label">Number of Sisters
                  </label>
                  <div class="col-md-4">
                    {{ student.number_of_sisters }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Attachments -->
            <div class="Attachments" style="padding-bottom: 20px">
              <div class="form-group row">
                <label class="col-md-12 col-form-label" style="color: #781b20"><i class="custom-left-border"></i>
                  Attachments</label>
              </div>

              <div class="custom-left-tall-border">
                <div class="form-group row">
                  <label class="col-md-6 col-form-label">Student Birth Certificate
                    <small v-if="student.student_birth_certificate_file_path"><a style="
                                      color: blue;
                                      font-style: italic;
                                      font-weight: 700;
                                      cursor: pointer;
                                    " @click.prevent="
                                      downloadFile(
                                        student.student_birth_certificate_file_path,
                                        'student_birth_certificate_file'
                                      )
                                    " target="_blank">Download file</a></small>
                  </label>
                  <label class="col-md-6 col-form-label">Student Vaccination Certificate
                    <small v-if="student.student_vaccination_certificate_file_path"><a style="
                                      color: blue;
                                      font-style: italic;
                                      font-weight: 700;
                                      cursor: pointer;
                                    " @click.prevent="
                                      downloadFile(
                                        student.student_vaccination_certificate_file_path,
                                        'student_vaccination_certificate_file'
                                      )
                                    " target="_blank">Download file</a></small>
                  </label>
                </div>
                <div class="form-group row" v-if="student.parents_data">
                  <label class="col-md-6 col-form-label" v-for="(parent_row, index) in student.parents_data"
                    :key="index">{{ parent_row.relation_to_std }} National ID
                    <small v-if="parent_row.national_id_file"><a style="
                                      color: blue;
                                      font-style: italic;
                                      font-weight: 700;
                                      cursor: pointer;
                                    " @click.prevent="
                                      downloadFile(
                                        parent_row.national_id_file,
                                        parent_row.relation_to_std
                                      )
                                    " target="_blank">Download file</a></small>
                  </label>
                </div>
              </div>
            </div>

            <!-- Transportation -->
            <div class="Transportation" style="padding-bottom: 20px">
              <div class="form-group row">
                <label class="col-md-12 col-form-label" style="color: #781b20"><i class="custom-left-border"></i>
                  Transportation</label>
              </div>
              <div class="custom-left-tall-border">
                <div class="table-responsive">
                  <table class="table table-bordered table-striped verticle-middle table-responsive-sm">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Tracking ID</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody v-if="student.buses">
                      <tr v-for="bus in student.buses" :key="bus.id">
                        <td>{{ bus.name }}</td>
                        <td>{{ bus.bus_tracking_id }}</td>
                        <td>
                          <span>
                            <router-link v-can="'edit_buses'" :to="{
                              name: 'Buses-Show',
                              params: { id: bus.id },
                            }" title="View" class="mr-4"><i class="fa fa-eye color-muted"></i>
                            </router-link>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td style="text-align: center" colspan="8">
                          No Data Available.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <img v-if="student.img_url && student.img_url != null" :src="student.img_link" style="width:100%">
        <img v-else src="~@/assets/imgs/Profile_pic_placeholder.png" style="width:100%">
      </div>

      <!-- Manage payment profile -->
      <div v-can="'lock_payment_profile_students'" class="modal fade" id="managePaymentProfile" tabindex="-1"
        role="dialog" aria-labelledby="managePaymentProfileLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="managePaymentProfileLabel">
                Payment Profile Lock
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form action="">
                <div class="form-group row" v-if="student.payment_profile_year">
                  <label style="color: red" class="col-md-12 col-form-label">This student Locked on:
                    {{ student.payment_profile_year }} year</label>
                </div>
                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Tuition Fees</label>
                  <div class="col-md-9">
                    <select class="form-control" name="payment_year_id" required v-model="student.payment_year_id">
                      <option value="null" disabled selected>
                        Nothing Selected
                      </option>
                      <option v-for="(year, i) in lists.years" :key="i" :value="i">
                        {{ year }}
                      </option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" id="close" data-dismiss="modal">
                Cancel
              </button>
              <button type="button" :disabled="disableBtn" class="btn btn-primary"
                @click.prevent="mangeLock('lockPaymentProfileToStd')">
                Lock
              </button>
              <button type="button" :disabled="disableBtn" class="btn btn-primary"
                @click.prevent="mangeLock('unlockPaymentProfileToStd')">
                Unlock
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Manage payment profile -->
      <div v-can="'lock_payment_profile_students'" class="modal fade" id="transferStdClass" tabindex="-1" role="dialog"
        aria-labelledby="transferStdClassLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="transferStdClassLabel">
                Transfer Student Class
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- FeedBack -->
              <feedback-messages :msgs="msgs" :errors="errors" />
              <!-- /FeedBack -->
              <form action="">
                <div class="form-group row" v-if="student.payment_profile_year">
                  <label style="color: red" class="col-md-12 col-form-label">Cuttent Student Class:
                    {{ student.class.name }}</label>
                </div>
                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Classes</label>
                  <div class="col-md-9">
                    <select class="form-control" name="class_id" v-model="transfer_to_class_id" required>
                      <option value="null" disabled selected>
                        Nothing Selected
                      </option>
                      <option v-for="(row, i) in lists.classes" :key="i" :value="i">
                        {{ row }}
                      </option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" id="close" data-dismiss="modal">
                Cancel
              </button>
              <button type="button" :disabled="disableBtn" class="btn btn-primary" @click.prevent="transfer()">
                Transfer
              </button>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import FeedbackMessages from '@/components/FeedbackMessages.vue';
import useStudent from "@/composables/students";
import { inject, ref, onBeforeMount } from "vue";
import { useRouter } from "vue-router";

export default {
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  components: {
    FeedbackMessages
  },
  setup(props) {
    const hasPermission = inject("hasPermission");
    const router = useRouter();
    const disableBtn = ref(false);
    const swal = inject("$swal");
    const student_id = ref(0);
    const transfer_to_class_id = ref(null);

    const {
      student,
      lists,
      getStudent,
      transferStudentClass,
      mangeLockPaymentProfileToStd,
      sendCurrentTermResultsReport,
      downloadStudentFile,
      msgs,
      errors,
    } = useStudent();

    onBeforeMount(async () => {
      //first: check the user Permission
      if (!hasPermission("add_students"))
        router.push({
          name: "401",
        });

      await getStudent(props.id);
      student_id.value = student.value.id;
    });

    const mangeLock = async (action) => {
      if (!hasPermission("lock_payment_profile_students")) return false;

      disableBtn.value = true;
      mangeLockPaymentProfileToStd(action, {
        student_id: student.value.id,
        payment_year_id: student.value.payment_year_id,
      }).then(() => {
        window.scrollTo(0, 0);
        disableBtn.value = false;
      });
      document.getElementById("close").click();
      await getStudent(props.id);
    };

    const downloadFile = async (filepath, filetype) => {
      await downloadStudentFile(filepath, filetype);
    };

    const sendResultsReport = async () => {
      swal.fire({
        title: "Please wait while process your request",
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading();
        },
      });

      await sendCurrentTermResultsReport(student_id.value).then((resp) => {
        console.log("respp", resp);
        swal.close();
        if (resp != "fail") {
          swal.fire({
            icon: "success",
            title: "Completed...",
            text: resp.data,
          });
        }
        window.scrollTo(0, 0);
        disableBtn.value = false;
      });
    };

    const transfer = async () => {

      if (transfer_to_class_id.value == null)
        return false;

      disableBtn.value = true;
      transferStudentClass({
        student_id: student.value.id,
        class_id: transfer_to_class_id.value
      }).then(() => {
        getStudent(props.id);
        window.scrollTo(0, 0);
        disableBtn.value = false;
      });
      document.getElementByClass("close").click();
    }

    return {
      student,
      student_id,
      lists,
      mangeLock,
      disableBtn,
      downloadFile,
      sendResultsReport,
      errors,
      msgs,
      transfer_to_class_id,
      transfer
    };
  },
};
</script>
